<template>
  <div class="report">
    <Header back="返回" title="汇报管理" index="首页" beforeTitle="汇报管理" />
    <div class="content">
      <el-form
        :label-position="labelPosition"
        :model="reportform"
        :rules="reportRule"
        ref="reportRule"
        label-width="80px"
      >
        <h1>{{ reportform.title }}</h1>
        <el-form-item label="今天完成的工作">
          <p>{{ this.reportform.completed_work }}</p>
        </el-form-item>
        <el-form-item label="未完成的工作">
          <p>{{ this.reportform.incomplete_work }}</p>
        </el-form-item>
        <el-form-item label="明天的计划">
          <p>{{ this.reportform.plan }}</p>
        </el-form-item>
        <el-form-item label="工作心得">
          <p>{{ this.reportform.experience }}</p>
        </el-form-item>
        <el-form-item label="备注">
          <p>{{ this.reportform.remark }}</p>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      typeStr: ["日报", "周报", "月报"],
      labelPosition: "top",
      reportform: {
        completed_work: "",
        incomplete_work: "",
        plan: "",
        experience: "",
        remark: "",
        itemId: "",
        cc_id: "",
        type: "",
        title: "",
      },
      reportRule: {
        completed_work: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        incomplete_work: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        plan: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        experience: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
      },
      options: [],
      moptions: [],
      value: "",
      input: "",
      trustname: "",
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    this.trustname = localStorage.getItem("myname");
    if (this.meber_id) {
      this.axios
        .get("/api/report/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          this.reportform = res.data;
          this.reportform.id = this.meber_id;
        });
    }
  },
  methods: {},
};
</script>

<style scoped="scoped">
.report h1 {
  font-size: 20px;
  font-weight: bold;
}

.report h2 {
  font-size: 17px;
  font-weight: bold;
  color: #000000;
}

.el-select {
  margin-top: 20px;
  margin-right: 10px;
}
</style>
